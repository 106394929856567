
import { defineComponent } from "vue";
import dayjs from "dayjs";
import { generateDaySlots, generateHourlyTimeSlots } from "./-component";
export default defineComponent({
  name: "SearchByDate",
  props: {
    mode: {
      type: String,
      required: true,
    },
    searchDay: {
      type: String,
    },
    searchTime: {
      type: String,
    },
    searchEndTime: {
      type: String,
    },
    searchDate: {
      type: Date,
    },
  },
  computed: {
    formattedPlaceholder() {
      return this.searchDate
        ? dayjs(this.searchDate).format("ddd, M/D/YY") +
            " at " +
            dayjs(this.searchDate).format("h:mm A")
        : "Search Time";
    },
  },
  data() {
    const times = generateHourlyTimeSlots();
    const endTimes = generateHourlyTimeSlots();
    const days = generateDaySlots();
    const today = dayjs().day();
    return {
      today,
      day: this.searchDay,
      time: this.searchTime,
      endTime: this.searchEndTime,
      date: this.searchDate,
      endTimes,
      days,
      times,
      flow: ["calendar", "time"] as Array<string>,
    };
  },
  methods: {
    isValidEndTime(et: string) {
      return +et <= +this.time;
    },
    dayjs,
    changeDate(d: Date | null) {
      if (d !== this.searchDate) {
        this.$emit("change-date", d);
        this.date = dayjs(d);
      }
    },
    changeDay(e: Event) {
      const el = e.target as HTMLSelectElement;
      const d = el.value;
      if (d !== this.day) {
        this.$emit("change-day", d);
        this.day = d;
      }
    },
    changeTime(e) {
      if (e.target?.value !== this.time) {
        if (e.target?.value === "") {
          this.endTime = "";
          this.$emit("change-end-time", { value: "", doNotSearch: true });
        }
        if (e.target?.value >= this.endTime) {
          this.endTime = "";
          this.$emit("change-end-time", { value: "", doNotSearch: true });
        }
        this.$emit("change-time", e.target?.value);
        this.time = e.target?.value;
      }
    },
    changeEndTime(e) {
      if (e.target?.value !== this.endTime) {
        this.$emit("change-end-time", {
          value: e.target?.value,
          doNotSearch: false,
        });
        this.endTime = e.target?.value;
      }
    },
  },
});
