
import { SearchTerm } from "../../types";
import { defineComponent, PropType } from "vue";
import VenueSearchButton from "./VenueSearchButton.vue";
export default defineComponent({
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  emits: ["update-search"],
  props: {
    searchTerms: {
      type: Array as PropType<Array<SearchTerm>>,
      required: true,
    },
  },
  methods: {
    updateSearch(term: SearchTerm) {
      this.$emit("update-search", term.string);
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  },
  components: { VenueSearchButton },
});
