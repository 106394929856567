
import { defineComponent } from "vue";
import {
  getLocalVenuesAndSpecials,
  debounce,
  getRandomCoordinatesAroundRichmond,
  sendGtagEvent,
} from "../../common/common";
import Venue from "./Venue.vue";
import LocalService from "../../services/LocalService";
import { useToast, TYPE } from "vue-toastification";
import NotifyForMoreRestaurants from "./NotifyForMoreRestaurants.vue";
import VenueSearch from "./VenueSearch.vue";
import { createDefaultSearchTerms } from "./-SearchTerms";
import { SearchTerm, Venue as VenueType } from "../../types";
import dayjs from "dayjs";
import { EventBrowseMode } from "@/types";
import {
  showMapOfSpecialsModal,
  showFeedbackModal,
  showDropAPinModal,
} from "../../components/Modals/ModalController";
import { Keyboard } from "@capacitor/keyboard";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ogImage from "@/assets/ogimg.png";
import eventBus from "../EventBus";
import { Capacitor } from "@capacitor/core";
import SearchByDate from "./SearchByDate.vue";
export default defineComponent({
  head() {
    return {
      title: "Explore Specials",
      meta: [
        {
          name: "description",
          content:
            "gophrr helps you find specials and plan events. View nearby deals, schedule events with friends and more. ",
        },
        {
          property: "og:description",
          content: "Happy hours, specials, and more on gophrr",
        },
        {
          property: "og:title",
          content: "Check out these specials on gophrr...",
        },
        {
          property: "og:image",
          content: "https://gophrr.io/" + ogImage,
        },
        {
          property: "og:url",
          content: "https://gophrr.io",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:site_name",
          content: "gophrr",
        },
      ],
    };
  },
  created() {
    this.searchDebounce = debounce(this.doSearch, 1250);
    // eventBus.$on("mainScrollDirection", (direction) => {
    //   const searchInput = document.getElementById("search-input");
    //   const isSearchInputFocused = document.activeElement === searchInput;
    //   if (window.scrollY < 10) {
    //     this.showSearch = true;
    //   } else if (!isSearchInputFocused) {
    //     this.showSearch = false;
    //     clearTimeout(this.showSearchTimeoutId);
    //     this.showSearchTimeoutId = setTimeout(() => {
    //       this.showSearch = true;
    //     }, 1000);
    //   }
    // });
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
    eventBus.$on("loadMoreVenues", async () => {
      await this.fetchMore();
      eventBus.$emit("refreshMap", this.venues);
    });
    eventBus.$on("setLatLong", async (l) => {
      await this.setLatLong(l);
    });
    eventBus.$on("updateRadius", (r) => {
      this.radius = r;
    });
    showDropAPinModal({ lat: this.lat, long: this.long }, this.radius);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll, true);
    eventBus.$off("loadMoreVenues");
    eventBus.$off("mainScroll");
    eventBus.$off("setLatLong");
    eventBus.$off("updateRadius");
  },
  methods: {
    showDropAPinModal,
    showSearchBar() {
      this.showSearch = !this.showSearch;
    },
    // showFiltersAndSearchIfNeeded() {
    //   this.showSearch = true;
    //   this.showFilters = !this.showFilters;
    // },
    getSearchDateTime() {
      const s = { day: "", time: "", date: null, endTime: "" };
      if (this.searchByTime) {
        if (this.mode === "onetime") {
          s.date = this.searchDate;
        } else if (this.mode === "weekly") {
          s.day = this.searchDay;
          s.time = this.searchTime;
          s.endTime = this.searchEndTime;
        } else {
          throw new Error("Invalid mode");
        }
      }
      return s;
    },
    changeEndTime(t) {
      const { value, doNotSearch } = t;
      if (this.searchEndTime !== value) {
        this.searchEndTime = value;
        if (!doNotSearch) {
          sendGtagEvent(this.$gtag, "special_search", {
            eventLabel: "search_custom_end_time_" + value ?? "",
            event_category: "engagement",
          });
          this.doSearch();
        }
      }
    },
    changeTime(t) {
      if (this.searchTime !== t) {
        this.searchTime = t;
        sendGtagEvent(this.$gtag, "special_search", {
          eventLabel: "search_custom_time_" + t ?? "",
          event_category: "engagement",
        });
        this.doSearch();
      }
    },
    changeDay(d) {
      if (this.searchDay !== d) {
        sendGtagEvent(this.$gtag, "special_search", {
          eventLabel: "search_custom_day_" + d ?? "",
          event_category: "engagement",
        });
        this.searchDay = d;
        this.doSearch();
      }
    },
    changeDate(d) {
      sendGtagEvent(this.$gtag, "special_search", {
        eventLabel: "search_custom_date" + d ?? "",
        event_category: "engagement",
      });
      if (this.searchDate !== d) {
        this.searchDate = d;
        this.doSearch();
      }
    },
    getSearchPlaceholder() {
      const searchWords = [
        "mojitos",
        "burger",
        "rails",
        "margarita",
        "taco",
        "karaoke",
        "trivia",
        "appetizers",
        "patio",
        "dog",
      ];
      return (
        "try typing '" +
        searchWords[Math.floor(Math.random() * searchWords.length)] +
        "'..."
      );
    },
    changeMode(m: EventBrowseMode) {
      this.mode = m;
      this.doSearch();
    },
    updateRadius(r) {
      this.radius = r;
    },
    searchXButtonClicked() {
      if (this.search !== "") {
        this.search = "";
        this.doSearch();
      }
    },
    async debounceThenSearch() {
      this.searchDebounce();
    },
    addOrRemoveFromMap(id: number): void {
      const index = this.markedVenues.findIndex((venue) => id === venue.id);
      if (index === -1) {
        const v = this.venues.find((v) => v.id === id);
        this.markedVenues.push(v);
      } else {
        this.markedVenues.splice(index, 1);
      }
    },
    showFeedbackModal,
    showMapModal() {
      this.loading = true;
      showMapOfSpecialsModal({ lat: this.lat, long: this.long }, this.venues);
      this.loading = false;
    },
    async setLatLong(l) {
      if ((!l.lat || !l.long) && !this.lat && !this.long) {
        const coords = getRandomCoordinatesAroundRichmond();
        l.lat = coords.lat;
        l.long = coords.long;
      } else if (
        this.lat === l.lat &&
        this.long === l.long &&
        this.lastSearchRadius === this.radius
      ) {
        // coordinates have not changed - don't redo search
        return;
      }
      this.page = 1;
      this.lat = l.lat ?? this.lat;
      this.long = l.long ?? this.long;
      this.settingCoordinates = false;
      await this.loadVenues();
      this.showingResults = true;
      window.scrollTo(0, 0);
    },
    async loadVenues() {
      const searchDateTime = this.getSearchDateTime();
      this.lastSearchRadius = this.radius;
      const v = await getLocalVenuesAndSpecials(
        this.mode,
        this.radius,
        { lat: this.lat, long: this.long },
        this.page,
        this.searchTerms,
        this.search,
        searchDateTime
      );
      if (this.mode === "weekly") {
        this.venues = v.venues;
      } else {
        this.venues = v.nonWeeklyVenues;
      }
      const noMore = v.noMore;
      this.loading = false;
      if (noMore) {
        this.noRestaurants = true;
      }
      if (this.venues.length < 10) {
        this.noMoreToLoad = true;
      }
      this.settingCoordinates = false;
      window.scrollTo(0, 0);
    },
    searchByTimeClicked() {
      if (this.searchByTime) {
        // if you de-select, clear the filters and search again
        this.searchTime = null;
        this.searchDay = null;
        this.searchEndTime = null;
        this.searchDate = null;
        this.doSearch();
      }
      sendGtagEvent(this.$gtag, "special_search", {
        eventLabel: "wants_to_search_by_time",
        event_category: "engagement",
      });
      this.searchByTime = !this.searchByTime;
      // turn off "Happening Now" since we're now filtering by time
      const index = this.searchTerms.findIndex(
        (v: SearchTerm) => v.string === "Happening Now"
      );
      if (index !== -1) {
        this.searchTerms[index].active = false;
      }
    },
    async doSearch() {
      if (this.platform === "android" || this.platform === "ios") {
        await Keyboard.hide();
      }
      this.noRestaurants = false;
      this.noMoreToLoad = false;
      this.loading = true;
      this.searchLoading = true;
      const searchDateTime = this.getSearchDateTime();
      this.lastSearchRadius = this.radius;
      this.page = 1;
      const v = await getLocalVenuesAndSpecials(
        this.mode,
        this.radius,
        { lat: this.lat, long: this.long },
        this.page,
        this.searchTerms,
        this.search,
        searchDateTime,
        this.utcOffset
      );
      if (this.mode === "weekly") {
        this.venues = v.venues;
      } else {
        this.venues = v.nonWeeklyVenues;
      }
      const noMore = v.noMore;
      this.loading = false;
      this.searchLoading = false;
      if (noMore) {
        this.noRestaurants = true;
      }
      if (this.venues.length < 10) {
        this.noMoreToLoad = true;
      }
      window.scrollTo(0, 0);
    },
    async updateSearch(term: string) {
      sendGtagEvent(this.$gtag, "special_search", {
        eventLabel: "using_search_terms",
        event_category: "engagement",
      });
      this.loadingMore = true;
      this.loading = true;
      this.noRestaurants = false;
      this.noMoreToLoad = false;
      this.page = 1;
      const index = this.searchTerms.findIndex(
        (v: SearchTerm) => v.string === term
      );
      this.searchTerms[index].active = !this.searchTerms[index].active;
      if (
        this.searchTerms[
          this.searchTerms.findIndex((s) => s.string === "Happening Now")
        ].active
      ) {
        this.searchByTime = false;
      }
      const lCoords = { lat: this.lat, long: this.long };
      const searchDateTime = this.getSearchDateTime();
      this.lastSearchRadius = this.radius;
      const v = await getLocalVenuesAndSpecials(
        this.mode,
        this.radius,
        lCoords,
        this.page,
        this.searchTerms,
        this.search,
        searchDateTime,
        this.utcOffset
      );
      this.noMoreToLoad = v.noMore;
      this.noRestaurants = this.noMoreToLoad;
      if (this.mode === "weekly") {
        this.venues = v.venues;
      } else {
        this.venues = v.nonWeeklyVenues;
      }
      this.loadingMore = false;
      this.loading = false;
      window.scrollTo(0, 0);
    },

    onScroll() {
      const documentHeight = document.body.scrollHeight;
      const currentScroll = window.scrollY + window.innerHeight;

      // When the user is [modifier]px from the bottom, fire the event.
      const modifier = 200;
      if (currentScroll + modifier > documentHeight) {
        if (
          !this.noRestaurants &&
          !this.loadingMore &&
          !this.settingCoordinates
        ) {
          this.fetchMore();
        }
      }
    },
    async fetchMore() {
      if (!this.noMoreToLoad && !this.loadingMore) {
        this.loadingMore = true;
        this.page = this.page + 1;
        const lCoords = { lat: this.lat, long: this.long };
        const searchDateTime = this.getSearchDateTime();
        this.lastSearchRadius = this.radius;
        const v = await getLocalVenuesAndSpecials(
          this.mode,
          this.radius,
          lCoords,
          this.page,
          this.searchTerms,
          this.search,
          searchDateTime,
          this.utcOffset
        );
        const moreVenues =
          this.mode === "weekly" ? v.venues : v.nonWeeklyVenues;
        const noMore = v.noMore;
        if (noMore) {
          this.noMoreToLoad = true;
        } else {
          this.venues = this.venues.concat(moreVenues);
        }
        this.loadingMore = false;
      }
    },
    async notifyMeAboutSpecials(e) {
      try {
        const { email, coordinates } = e;
        const isValidEmail =
          /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
        const toast = useToast();
        if (!isValidEmail) {
          toast("Please enter a valid email.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else {
          const r = await LocalService.notifyMeAboutSpecials(
            email,
            coordinates
          );
          if (r.status == 200) {
            toast("Success! You're signed up.", {
              timeout: 5000,
              type: TYPE.SUCCESS,
              hideProgressBar: true,
            });
            this.signedUp = true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  data() {
    const utcOffset = dayjs().utcOffset() / 60;
    const platform = Capacitor.getPlatform();
    const tapOrClick = window.innerWidth < 700 ? "Tap" : "Click";
    let searchTerms = createDefaultSearchTerms();
    return {
      platform,
      tapOrClick,
      utcOffset,
      showSearchTimeoutId: null,
      scrollPosition: window.scrollY as number,
      showSearch: true as boolean,
      showFilters: true as boolean,
      mode: "weekly" as EventBrowseMode,
      radius: 10,
      lastSearchRadius: null as number | null,
      searchDebounce: null,
      search: "" as string,
      searchLoading: false as boolean,
      tryingToLoadAgain: false as boolean,
      settingCoordinates: true as boolean,
      showingResults: false as boolean,
      searchTerms,
      searchByTime: false as boolean,
      searchTime: null as string,
      searchDay: null as string,
      searchEndTime: null as string,
      searchDate: null as dayjs.Dayjs | null,
      lat: null,
      long: null,
      signedUp: false,
      venues: [],
      page: 1,
      noMoreToLoad: false,
      loading: true,
      loadingMore: false,
      noRestaurants: false,
      showingMapView: false as boolean,
      showingListView: true as boolean,
      markedVenues: [],
    };
  },
  computed: {
    searchLoadingClass() {
      return this.searchLoading
        ? "spinner-border spinner-border-sm"
        : "bi bi-search";
    },
    mapButtonClass() {
      return this.venues.length > 0
        ? "position-absolute top-0 start-100 translate-middle badge bg-light border border-3 border-nice-blue text-dark"
        : "position-absolute top-0 start-100 translate-middle badge bg-light border text-dark";
    },
    floatingButtonIcon() {
      return this.showingListView ? "bi bi-map" : "bi bi-card-list";
    },

    searchTimeClass() {
      return this.searchByTime
        ? "badge rounded-pill border bg-success mx-1"
        : "badge rounded-pill border border-dark bg-grayish text-dark mx-1";
    },
  },
  components: {
    Venue,
    NotifyForMoreRestaurants,
    VenueSearch,
    SearchByDate,
  },
});
