import { NonWeeklySpecial } from "../../types";

export function generateNonWeeklySpecial(): NonWeeklySpecial {
  return {
    id: null,
    name: "",
    description: "",
    type: null,
    price: "",
    holiday: "",
    popular: 0,
    coverCost: 0,
    imageUrl: "",
    isPromoted: false,
    isFamilyFriendly: false,
    isOneNightOnly: false,
    VenueId: null,
    website: "",
    tags: [],
  };
}

type SimpleTimeSlot = {
  time: string;
  number: string | null;
};

export function generateHourlyTimeSlots(): SimpleTimeSlot[] {
  return [
    { time: "12:00 AM", number: "0" },
    { time: "1:00 AM", number: "1" },
    { time: "2:00 AM", number: "2" },
    { time: "3:00 AM", number: "3" },
    { time: "4:00 AM", number: "4" },
    { time: "5:00 AM", number: "5" },
    { time: "6:00 AM", number: "6" },
    { time: "7:00 AM", number: "7" },
    { time: "8:00 AM", number: "8" },
    { time: "9:00 AM", number: "9" },
    { time: "10:00 AM", number: "10" },
    { time: "11:00 AM", number: "11" },
    { time: "12:00 PM", number: "12" },
    { time: "1:00 PM", number: "13" },
    { time: "2:00 PM", number: "14" },
    { time: "3:00 PM", number: "15" },
    { time: "4:00 PM", number: "16" },
    { time: "5:00 PM", number: "17" },
    { time: "6:00 PM", number: "18" },
    { time: "7:00 PM", number: "19" },
    { time: "8:00 PM", number: "20" },
    { time: "9:00 PM", number: "21" },
    { time: "10:00 PM", number: "22" },
    { time: "11:00 PM", number: "23" },
  ];
}

type SimpleDaySlot = {
  day: string;
  number: string | null;
};

export function generateDaySlots(): SimpleDaySlot[] {
  return [
    { day: "Sunday", number: "0" },
    { day: "Monday", number: "1" },
    { day: "Tuesday", number: "2" },
    { day: "Wednesday", number: "3" },
    { day: "Thursday", number: "4" },
    { day: "Friday", number: "5" },
    { day: "Saturday", number: "6" },
  ];
}
