
import { SearchTerm } from "@/types";
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["update-search"],
  props: {
    searchTerms: {
      type: Array,
      required: true,
    },
    term: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateSearch(term) {
      const termString = term.string;
      this.$emit("update-search", term);
      this.$refs[termString].blur();
    },
  },
  computed: {
    buttonClass() {
      return this.searchTerms.find((t) => t.string === this.term.string).active
        ? "badge rounded-pill border bg-success mx-1"
        : "badge rounded-pill border border-dark bg-grayish text-dark mx-1";
    },
  },
});
