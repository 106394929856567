import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    ref: _ctx.term.string,
    class: _normalizeClass(_ctx.buttonClass),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateSearch(_ctx.term))),
    role: "button"
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.term.icon)
    }, null, 2),
    _createTextVNode(" " + _toDisplayString(_ctx.term.string), 1)
  ], 2))
}