import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VenueSearchButton = _resolveComponent("VenueSearchButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(
        _ctx.windowWidth < 576
          ? 'fs-5 d-inline-flex hide-scroll w-100'
          : 'fs-5 d-inline-flex hide-scroll justify-content-center w-100'
      ),
      style: {"overflow-x":"auto"}
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchTerms, (term) => {
        return (_openBlock(), _createBlock(_component_VenueSearchButton, {
          key: term.string,
          term: term,
          searchTerms: _ctx.searchTerms,
          onUpdateSearch: ($event: any) => (_ctx.updateSearch(term))
        }, null, 8, ["term", "searchTerms", "onUpdateSearch"]))
      }), 128))
    ], 2)
  ]))
}